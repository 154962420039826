<template>
  <div class="short_video">
    <div class="video-s">
      <Swiper class="video-swiper" :options="swiperOption" ref="mySwiper" v-if="storeVideoList.list.length > 0">
        <SwiperSlide class="my_swiper_silde" v-for="(item, index) in storeVideoList.list" :key="item.id">
          <Video
            v-if="index > storeVideoList.index - 2 && index < storeVideoList.index + 2"
            :videoShow="storeVideoList.index == index"
            :videoInfo="item"
            :index="index"
          />
          <div v-else></div>
        </SwiperSlide>
      </Swiper>
      <div class="emptyErr" v-else-if="!(storeVideoList.list.length > 0) && !loading">{{ emptyText }}</div>
      <!--    loading-->
      <Loading v-if="loading" />
    </div>
    <div class="left_video_adv" v-if="advList.length">
      <div class="video_adv_parent">
        <div :class="['video_adv_parent_img', { width20: !isAdvShow }]" @click="clickAdv"><ImgDecypt :src="advList[0].cover" /></div>
        <div class="close" @click="advClose" v-show="isAdvShow">
          <svg-icon icon-class="close4" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { Tab, Tabs } from 'vant';
import Video from '@/views/shortVideo/video';
import { queryVidList } from '@/api/video';
import Loading from '@/components/Loading';
import { mapGetters } from 'vuex';
import { AdType, getAdItem } from '@/utils/getConfig';
import { jumpAdv } from '@/utils/getConfig';

export default {
  name: 'shortVideo',
  components: {
    Video,
    Swiper,
    SwiperSlide,
    Loading,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    ImgDecypt: () => import('@/components/ImgDecypt/index.vue'),
  },
  computed: {
    ...mapGetters({
      storeVideoList: 'videoObj',
    }),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    return {
      isAdvShow: true,
      advList: [],
      videoPage: 1, //短视频请求页码
      isHotNodata: false, // 热门没数据
      videoSize: 10, //一页短视频的条数
      AdvNum: 0,
      emptyText: '看累了视频去玩会儿棋牌吧，新鲜短视频正在上架〜',
      loading: true,
      swiperOption: {
        direction: 'vertical',
        freeModeMomentumRatio: 0,
        on: {
          slideChangeTransitionEnd: () => {
            let activeIndex = this.swiper.activeIndex;
            this.$store.commit('video/UPDATE_VIDEOLIST_INDEX', { index: activeIndex });
            let { list, index } = this.storeVideoList;
            //滑到倒数第二条数据加载下一页视频数据
            if (index === list.length - 2) {
              this.videoPage++;
              this.getVidList();
            }
            // 如果数据大于3页就删除1页数据
            if (index === 2 * this.videoSize && list.length === 3 * this.videoSize) {
              this.swiper.slideTo(index - this.videoSize, 0, true);
              //切换到第二页删除第一页数据
              this.$store.commit('video/UPDATE_VIDEOLIST_INDEX', { index: index - this.videoSize });
              this.$store.commit('video/DELETE_VIDEOLIST', { index: this.videoSize });
            }
          },
        },
      },
    };
  },
  created() {
    this.advList = getAdItem(AdType.SHORT_VIDEO_LEFT_ADV);
    if (this.storeVideoList?.list.length > 0) {
      this.$nextTick(() => {
        this.swiper.slideTo(this.storeVideoList.index, 0, true);
      });
      setTimeout(() => {
        this.loading = false;
      }, 300);
    } else {
      this.getVidList();
    }
  },
  methods: {
    //获取视频推荐列表
    async getVidList() {
      let req = {
        pageNumber: String(this.videoPage),
        pageSize: String(this.videoSize),
      };
      let ret = await this.$Api(queryVidList, req);
      if (ret && ret.code === 200) {
        this.loading = false;
        if (ret.data.vInfos !== null) {
          let vidList = this.storeVideoList?.list?.concat(ret.data.vInfos);
          let AdvNum = this.AdvNum;
          for (let i = 0; i < vidList.length; i++) {
            if ((AdvNum + 1) * 6 + AdvNum < vidList.length) {
              if (vidList[(AdvNum + 1) * 6 + AdvNum]['type'] == 0) {
                return;
              }
              if (this.$store.getters.adsVerticalList.list.length > 0) {
                let itemAd = this.$store.getters.adsVerticalList.list[this.$store.getters.adsVerticalList.index];
                itemAd.type = 0;
                vidList.splice((AdvNum + 1) * 6 + AdvNum, 0, itemAd);
                this.$store.commit('video/UPDATE_ADS_VERTICAL_LIST_INDEX');
                AdvNum += 1;
              }
            }
          }
          this.AdvNum = AdvNum;
          this.$store.commit('video/SET_VIDEOLIST', {
            isFocus: false,
            list: vidList,
          });
        }
      }
    },
    advClose() {
      this.isAdvShow = false;
    },
    clickAdv() {
      if (!this.advList.length) return;
      if (!this.isAdvShow) {
        this.isAdvShow = true;
        return;
      }
      jumpAdv(this.advList[0]);
    },
  },
};
</script>
<style lang="scss" scoped>
.width20 {
  width: 20px !important;
}
.left_video_adv {
  position: absolute;
  top: 65px;
  left: 10px;
  z-index: 3000;

  .video_adv_parent {
    border-radius: 5px;
    position: relative;
    .video_adv_parent_img {
      width: 80px;
      height: 80px;
      border-radius: 5px;
      overflow: hidden;
      transition: all 0.25s ease-in-out;
    }
    .close {
      position: absolute;
      top: -10px;
      right: -16px;
      width: 18px;
      height: 18px;
      z-index: 111;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

.short_video {
  height: 100%;
  width: 100%;
  background: #000000;
  position: relative;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  overflow: hidden;

  .my_tab {
    position: absolute;
    top: 0;
    height: 50px;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #abaaac;

    .search_box {
      position: absolute;
      right: 16px;

      .search {
        > svg {
          font-size: 18px;
        }
      }
    }

    .my_tab_b {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 160px;

      .tab_item {
        padding: 0 18px;
        font-size: 20px;
        position: relative;
      }

      .active {
        color: #ffffff;
      }

      .tab_item:nth-child(2).active ~ .b_line {
        left: 108px;
      }
    }
  }

  .video-s {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    .emptyErr {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: #ccc;
    }
    .video-swiper {
      height: 100%;
      width: 100%;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);

      .my_swiper_silde {
        width: 100%;
        height: 100%;
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
      }
    }
  }
}
</style>
