<template>
  <div class="video_warp">
    <div class="video_box">
      <div class="video_adv_box" v-if="videoInfo['type'] == 0" @click="jumpAdvClick(videoInfo)">
        <div class="video_adv">
          <img-decypt class="video_adv_img" :src="videoInfo.cover" @load="loadAdv" />
        </div>
        <!--loading-->
        <div class="video_play_btn">
          <Loading v-show="!loadAdvImg" />
          <div class="adv_play">
            <svg-icon icon-class="tip_play2" v-show="loadAdvImg" />
          </div>
        </div>
      </div>
      <VerticalScreen
        v-else-if="videoShow && videoInfo['type'] != 0"
        :videoInfo="videoInfo"
        @vipBuyShow="vipBuyShow"
        @goldBuyShow="goldBuyShow"
        @watchCountData="watchCountData"
        ref="verticalScreen"
      />
      <div class="layer" v-if="videoInfo['type'] != 0">
        <div class="left" :class="{ leftHeight: !(isVip && videoInfo.originCoins == 0) }" v-show="isEyeShow">
          <div class="playerCountBox" v-if="!isVip && !isCan && videoInfo.originCoins == 0" @click="paying('vip')">开通VIP观看完整版</div>
          <div class="playerCountBox" v-else-if="!isVip && freeWatchNum >= 0 && videoInfo.originCoins == 0">
            免费视频剩余 :
            <span>{{ freeWatchNum }}</span>
          </div>
          <div class="tag">
            <div class="tag_item" v-for="item in videoInfo.tags && videoInfo.tags.slice(0, 5)" :key="item.id" @click="openTag(item)">
              {{ item.name }}
            </div>
          </div>
          <div class="title_text">{{ videoInfo.title }}</div>
        </div>
        <div class="right">
          <div class="right_content">
            <div>
              <div class="right_item" @click="collecte" :style="{ visibility: isEyeShow ? 'visible' : 'hidden' }">
                <svg-icon :icon-class="videoInfo.vidStatus && videoInfo.vidStatus.hasCollected ? 'xined' : 'xin'" />
                <span>{{ videoInfo.likeCount | watchCount }}</span>
              </div>
              <div class="right_item pt10" @click.stop="isEyeShow = !isEyeShow">
                <svg-icon :icon-class="isEyeShow ? 'eye_up' : 'eye_down'" />
                <span>{{ isEyeShow ? '隐藏' : '显示' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 会员购买弹窗-->
    <VipBuy class="buy_parent" v-if="videoShow && videoInfo['type'] != 0" :show="isVipbuyShow" @close="isVipbuyShow = false" />
  </div>
</template>
<script>
import Loading from '@/components/Loading';
import { jumpAdv } from '@/utils/getConfig';
import VerticalScreen from '@/components/Video/VerticalScreen';
import ImgDecypt from '@/components/ImgDecypt';
import { cancleZan, careCollectVideo, careOrcancle, zan } from '@/api/user';
import { Toast } from 'vant';
import { mapGetters, mapState } from 'vuex';
import VipBuy from '@/components/VipBuy';

export default {
  props: {
    videoShow: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    videoInfo: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  components: {
    VerticalScreen,
    ImgDecypt,
    VipBuy,
    Loading,
  },
  data() {
    return {
      isEyeShow: true,
      isLoading: true, //loading
      isGoldbuyShow: false, // 金币购买弹窗
      isVipbuyShow: false, //VIP购买弹窗
      isVipCardbuyShow: false,
      freeWatchNum: 0, //免费观看次数
      isCan: false, //是否能观看 true 能观看，false不能观看 (只有会员视频才能判断)
      isRequest: false,
      loadAdvImg: false,
      startX: 0,
      startY: 0,
    };
  },
  computed: {
    ...mapGetters({
      videoApi: 'videoApi',
      userInfo: 'userInfo',
      isVip: 'isVip',
    }),
    ...mapState({
      countdownSecInfo: (state) => state.user?.countdownSecInfo,
    }),
  },
  methods: {
    //关注
    async focus() {
      if (this.videoInfo?.publisher.hasFollowed) {
        return;
      }
      let uid = this.videoInfo?.publisher?.uid;
      let req = {
        followUID: parseInt(uid),
        isFollow: true,
      };
      if (!uid) {
        Toast('当前用户信息错误');
        return;
      }
      try {
        let res = await this.$Api(careOrcancle, req);
        if (res.code === 200) {
          this.videoInfo.publisher.hasFollowed = true;
          Toast('关注成功');
          this.$store.commit('video/UPDATE_ALL_FOCUS', uid);
          return;
        }
        Toast(res.tip || '关注失败');
      } catch (e) {
        Toast('关注失败');
      }
    },
    //收藏
    async collecte() {
      let isCollect = this.videoInfo?.vidStatus?.hasCollected;
      let id = this.videoInfo?.id;
      let req = {
        type: 'video',
        objID: id,
        isCollect: !isCollect,
      };
      try {
        let res = await this.$Api(careCollectVideo, req);
        if (res.code === 200) {
          this.videoInfo.vidStatus.hasCollected = !isCollect;
          if (isCollect) {
            Toast('取消点赞');
            this.videoInfo.likeCount--;
          } else {
            Toast('点赞成功');
            this.videoInfo.likeCount++;
          }
          return;
        }
        Toast('操作失败');
      } catch (e) {
        Toast('操作失败');
      }
    },
    goRouter() {
      let f = this.$route.query.fromPath;
      if (f == 'userHomePage') {
        this.$router.back();
        return;
      }
      this.$router.push(`/userHomePage?uid=${this.videoInfo.publisher.uid}`);
    },
    //点赞
    async like() {
      let hasLiked = this.videoInfo?.vidStatus?.hasLiked;
      let id = this.videoInfo?.id;
      let req = {
        type: 'video',
        objID: id,
      };
      try {
        if (hasLiked) {
          let res = await this.$Api(cancleZan, req);
          if (res.code === 200) {
            this.videoInfo.vidStatus.hasLiked = false;
            this.videoInfo.likeCount--;
            Toast('取消点赞');
            return;
          }
          Toast(res.tip || '取消失败');
        } else {
          let res = await this.$Api(zan, req);
          if (res.code === 200) {
            this.videoInfo.vidStatus.hasLiked = true;
            this.videoInfo.likeCount++;
            Toast('点赞成功');
            return;
          }
          Toast(res.tip || '点赞失败');
        }
      } catch (e) {
        Toast('操作失败');
      }
    },
    //前往标签页
    openTag(item) {
      // let fromPath = this.$route.query.fromPath;
      // if (fromPath == 'tags') {
      //   setSessionItem('tagId', item.id);
      //   this.$router.go(-1);
      //   return;
      // }
      this.$router.push({ path: '/tagVideo', query: { id: item.id, name: item.name, type: 'SP' } });
    },
    //免费观看次数
    watchCountData(data) {
      this.freeWatchNum = data.wCount;
      this.isCan = data.isCan;
      this.isRequest = true;
    },
    //vip视频
    vipBuyShow() {
      this.isVipbuyShow = true;
    },
    //金币视频
    goldBuyShow() {
      this.isGoldbuyShow = true;
    },
    jumpAdvClick(item) {
      jumpAdv(item);
    },
    loadAdv() {
      this.loadAdvImg = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.video_warp {
  height: 100%;
  .buy_parent {
    /deep/ .van-overlay {
      position: absolute;
    }
  }
  .video_box {
    width: 100%;
    height: 100%;
    background: #000000;
    position: relative;
    .video_adv_box {
      width: 100%;
      height: 100%;
      background: #000000;
    }
    .video_play_btn {
      width: 70px;
      height: 70px;
      border: none;
      outline: none;
      box-sizing: border-box;
      bottom: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .adv_play {
        width: 70px;
        height: 70px;
        background: rgba(0, 0, 0, 0.38);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 60px;
          height: 60px;
          color: #d8d5d1;
        }
      }
    }
    .video_adv {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .video_adv_img {
        width: 100%;
        height: auto;
      }
    }
    .layer {
      top: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      text-align: left;
      z-index: 9999;
      .left {
        position: absolute;
        bottom: 70px;
        left: 20px;
        width: 300px;
        color: #ffffff;
        pointer-events: none;
        .title_text {
          font-size: 12px;
          letter-spacing: 0.6px;
          width: 90%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .playerCountBox {
          height: 33px;
          width: fit-content;
          line-height: 33px;
          padding: 0 15px;
          border-radius: 33px;
          font-size: 12px;
          background-color: rgba(114, 114, 114, 0.5);
          border: 1px solid rgba(255, 255, 255, 0.5);
          margin-bottom: 6px;
          span {
            color: rgb(255, 0, 0);
          }
        }
        .tag {
          display: flex;
          margin: 1px 0 5px;
          pointer-events: auto;
          flex-wrap: wrap;
          .tag_item {
            font-size: 12px;
            color: white;
            letter-spacing: 0.6px;
            padding: 6px 12px;
            background-color: rgba(255, 255, 255, 0.15);
            margin-right: 8px;
            border-radius: 20px 20px 20px 0;
          }
        }
      }
      .right {
        position: absolute;
        right: 15px;
        bottom: 90px;
        width: 48px;
        color: #ffffff;
        pointer-events: none;
        .right_content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          pointer-events: auto;
          .right_item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            line-height: 16px;
            > span {
              font-size: 12px;
              padding-top: 2px;
            }
            > svg {
              height: 42px;
              width: 42px;
            }
          }
        }
      }
    }
  }
}

.pt10 {
  padding-top: 10px;
}
</style>
